@use '../../../scss/global/colors' as colors;
@use '../../../scss/utils/functions' as fn;
@import '../../../scss/utils/breakpoints';
@import '../../../scss/utils/mixins';

.formBox {
  @include flex_column;
  @include flex_align;

  width: 100%;

  margin-top: 30%;
  margin-bottom: 30%;
  background-color: colors.$base-bg;

  @include breakpoint-up('lg') {
    display: block;
    padding: fn.rem(124) 0;
    margin-top: 0;
    margin-bottom: 0;
    justify-content: center;
    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }
  h2 {
    color: colors.$text-dark;
    font-weight: 700;
    font-size: fn.rem(28);
    text-align: center;

    @include breakpoint-up('md') {
      font-size: fn.rem(30);
    }

    @include breakpoint-up('lg') {
      font-size: fn.rem(32);
    }
  }

  p {
    color: colors.$form-dark;
    font-size: fn.rem(16);
    text-align: center;
    margin-top: fn.rem(11);
    width: 60%;
    line-height: 128%;

    @include breakpoint-up('md') {
      font-size: fn.rem(18);
      width: 40%;
    }

    @include breakpoint-up('lg') {
      font-size: fn.rem(20);
      margin: 0 auto fn.rem(40);
    }
  }
}

.form {
  @include flex_column;
  @include flex_centered;
  gap: fn.rem(20);

  width: 85%;
  margin-top: fn.rem(40);

  @include breakpoint-up('md') {
    width: 65%;
  }

  @include breakpoint-up('lg') {
    @include flex_row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: fn.rem(446);
    margin: 0 auto;
  }
}

.formHolder,
.formHolderHalf {
  @include flex_column;
  justify-content: flex-start;
  gap: fn.rem(12);

  width: 100%;

  label {
    color: colors.$header-dark;
    font-weight: 600;
    font-size: fn.rem(16);

    @include breakpoint-up('md') {
      font-size: fn.rem(18);
    }

    @include breakpoint-up('lg') {
      font-size: fn.rem(20);
    }
  }

  small {
    color: red;
  }
}

.formHolderHalf {
  @include breakpoint-up('lg') {
    width: 47%;
  }
}

.inputHolder {
  width: 100%;
  height: fn.rem(48);
  position: relative;

  input {
    width: 100%;
    height: 100%;
    border: 1px solid #6b6b6b;
    border-radius: fn.rem(8);
    padding: 0 fn.rem(20);
  }

  input:focus {
    border: none;
    outline: 2px solid rgb(38,132,255);
    transition: 0.1s all ease;
  }

  svg {
    width: fn.rem(20);
    height: fn.rem(20);
    cursor: pointer;

    position: absolute;
    right: 4%;
    top: 50%;
    transform: translateY(-50%);
  }
}

.signIn {
  @include flex_align;
  justify-content: space-between;
  width: 100%;

  a,
  small {
    color: colors.$header-dark;
    font-weight: 600;
    font-size: fn.rem(14);

    @include breakpoint-up('md') {
      font-size: fn.rem(16);
    }
  }
}

.formBtn {
  width: 100%;
  margin-top: fn.rem(20);
}

.rememberMe {
  @include flex_centered;
  gap: fn.rem(4);
}

.formFooter {
  @include flex_centered;
  gap: fn.rem(8);

  font-size: fn.rem(14);
  font-weight: 600;
  color: colors.$text-dark;

  a {
    color: colors.$color-primary;
    font-size: fn.rem(14);
    font-weight: 600;
  }

  @include breakpoint-up('md') {
    font-size: fn.rem(16);

    a {
      font-size: fn.rem(16);
    }
  }

  @include breakpoint-up('lg') {
    margin: 0 auto;
  }
}
