@use '../../../scss/global/colors' as colors;
@use '../../../scss/utils/functions' as fn;
@import '../../../scss/utils/breakpoints';
@import '../../../scss/utils/mixins';

.container {
  width: 100%;
  height: 100%;
  background: #ffffff;

  @include breakpoint-up('lg') {
    height: 100vh;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  // SCREENS 1400px and LARGER
  @media only screen and (min-width: 1400px) {
    width: 100%;
    max-width: fn.rem(1400);
    margin: auto;
  }
}

.containerBody {
  height: 100dvh;
  padding: fn.rem(29) fn.rem(20) fn.rem(73);
  @include breakpoint-up('lg') {
    padding: 2rem 2.6% 4rem 4.5%;
    height: unset;
  }
}
