//Buttons
@use '../../scss/global/colors' as colors;
@use '../../scss/utils/functions' as fn;
@import '../../scss/utils/breakpoints';
@import '../../scss/utils/mixins';

.btnPrimary {
  background-color: colors.$color-primary;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(173, 173, 173, 0.16);

  color: var(--white-color);
  font-size: fn.rem(20);
  font-weight: 600;
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    background: var(--white-color);
    color: colors.$color-primary;
  }

  &:focus {
    border: 1px solid colors.$color-primary;
  }
}

.btnSecondary {
  border: 1.5px solid var(--primary-color);
  border-radius: 8px;
  outline: none;
  background: none;

  color: var(--primary-color);
  font-size: 1.25rem;
  font-weight: 600;
  cursor: pointer;

  transition: 0.4s;

  &:hover {
    color:  var(--white-color);
    background: colors.$color-primary;
  }

  &:focus {
    border: 1px solid colors.$color-primary;
  }
}

.btnTertiary {
  border: none;
  border-radius: 8px;
  outline: none;
  background: #fdfdfd;
  font-size: 20px;
  font-weight: 600;
  color: #121b98;
  cursor: pointer;
  transition: 0.4s;
  border: 1px solid colors.$light;

  &:hover {
    background: colors.$color-primary;
    color: colors.$light;
  }

  &:focus {
    border: 1px solid colors.$light;
  }

}

.paginationBttns {
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > :nth-child(1) {
    margin-right: 40px;
  }

  & > :nth-last-child(1) {
    margin-left: 28px;
  }

  svg {
    height: 32px;
    width: 32px;
    color: var(--black-color);
    border-radius: 50%;
    padding: 0;
    font-weight: 200;
    // border: 1px solid green;
    background: var(--white-color);
    box-shadow: 0px 4px 20px rgba(172, 172, 172, 0.16);
  }

  .pageNum {
    color: #979797;
    font-weight: 500;
    font-size: 18px;
    margin-right: 12px;
  }

  .paginationActive {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: var(--primary-color);
  }
}

.link_btn {
  width: 100%;

  align-items: center !important;
  gap: 4px;
  cursor: pointer;

  font-size: 1rem;
  color: var(--black-color);
}

.actionBtn {
  all: unset;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;

  svg,
  img {
    width: 24px;
    height: 24px;
    color: var(--black-color);
  }

  p {
    font-weight: 400;
    font-size: 16px;
    color: #525252;
  }
}

.accordion {
  transition: 0.3s;
}

.rotateUp {
  transform: rotate(-180deg);
}

.pill {
  font-size: 12px;
  font-weight: 600;

  border-radius: 100px;
  padding: 3px 12px;
}

//tableCard
.tableData {
  border-bottom: 1px solid #d8d8d8;
  text-align: center;

  p {
    color: var(--black-color);
    font-weight: 600;
    font-size: 16px;
  }

  small {
    color: #5b5b5b;
    font-size: 12px;
    font-weight: 400;
  }

  span {
    font-weight: 400;
    font-size: 16px;
  }

  .green {
    color: #10ad76;
  }

  .pending {
    color: #ce9500;
  }

  .red {
    color: #e0240a;
  }

  &:nth-child(1) {
    width: 50%;
    text-align: left;
    padding: 20px 0 8px 18px;
  }
}

//serach input
.searchInput {
  width: 100%;
  position: relative;
  transition: 0.3s;

  input {
    width: 100%;
    height: 100%;
    padding: 0 12%;
    border: 1px solid #a1a1a1;
    border-radius: 0.75rem;
    outline: none;

    &::placeholder {
      color: #8c8b8b;
      font-weight: 600;
      font-size: 1rem;
    }

    &:focus {
      border: 2px solid colors.$color-primary;

      svg {
        display: none;
      }
    }
  }

  svg {
    color: #8c8b8b;
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    left: 4%;
    top: 50%;
    bottom: 50%;
    transform: translateY(-50%);
  }
}

//input box

.inputBox {
  border: 1px solid #6b6b6b;
  border-radius: fn.rem(8);
  padding: 0 fn.rem(20);
}
