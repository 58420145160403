@use '../../../scss/global/colors' as colors;
@use '../../../scss/utils/functions' as fn;
@import '../../../scss/utils/breakpoints';
@import '../../../scss/utils/mixins';

.events {
  width: 100%;

  .events__body_head {
    @include flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    margin-bottom: 35px;

    .event_links {
      font-size: 24px;
      font-weight: 400;
      color: #767676;
      padding-bottom: 5px;
      transition: 0.3s all ease-in;
    }
    
    .active {
      color: var(--black-color);
      font-weight: 700;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 50%;
        border-bottom: 1px solid var(--primary-color);
      }
    }
  }
}
