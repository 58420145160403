// @use '../../../scss/global/colors' as colors;
@use '../../../scss/utils/functions' as fn;
// @import '../../../scss/utils/breakpoints';
// @import '../../../scss/utils/mixins';

.swiper_component {
    width: 100%;
    padding: fn.rem(20) fn.rem(20) fn.rem(20) 0;
    margin: 0;
    
    .swipes {
      margin-bottom: fn.rem(30);

      display: flex;
      align-items: center;
      justify-content: center ;
    }

    .swiper-pagination-bullet {
      width: fn.rem(11);
      height: fn.rem(7);
      border-radius: fn.rem(4);
      opacity: 1;
      background-color: #999595 !important;
    }

    .swiper-pagination-bullet-active {
      width: fn.rem(82);
    }
  }