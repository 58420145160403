@use '../../../scss/global/colors' as colors;
@use '../../../scss/utils/functions' as fn;
@import '../../../scss/utils/breakpoints';
@import '../../../scss/utils/mixins';


.selector {
  display: flex;
  flex-direction: column;
  gap: fn.rem(12);
  
  p {
    color: colors.$text-dark;
  }
  
  @include breakpoint-up('md') {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
}

.selectMenu {
  border: 1px solid #d1d1d1;
  border-radius: fn.rem(8);
  outline: none;
  position: relative;
  transition: 0.4s ease-in;
}

.selectMenu::before {
  content: '';
  width: 140%;
  height: 140%;
  background-color: red;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%) scaleX(1.25);
  transition: 0.8s cubic-bezier(.55, 0, 0.1, 1);
  z-index: -1;
}

.selectMenu::after {
  content: '';
  width: 180%;
  height: 190%;
  background-color: blue;
  position: absolute;
  left: 50%;
  top: 180%;
  transform: translateX(-50%) scaleX(1.45);
  transition: 0.6s cubic-bezier(.55, 0, 0.1, 1);
  z-index: -1;
}

.selectMenu:hover::before {
  top: -35%;
  background-color: pink;
  transform: translateX(-50%) scale(0.8);
}

.selectMenu:hover::after {
  top: -35%;
  background-color: pink;
  transform: translateX(-50%) scale(0.8);
}


.selectMenu .selectBtn {
  all: unset;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: fn.rem(14);
  font-weight: 400;
  color: colors.$header-dark;
  align-items: center;
  cursor: pointer;
}

.selectBtn svg {
  font-size: fn.rem(14);
  transition: 0.3s;
}

.rotateUp {
  transform: rotate(-180deg) !important;
}

.options {
  width: 200%;
  position: absolute;
  margin-top: fn.rem(30);
  margin-left: 0;
  border-radius: fn.rem(8);
  background: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  left: 0;
  z-index: 5;

  @include breakpoint-up('lg') {
    width: 100% !important;
  }
}

.option {
  display: flex;
  height: fn.rem(55);
  cursor: pointer;
  padding: fn.rem(14) fn.rem(16);
  align-items: center;
  width: 100%;
  font-size: fn.rem(14);
  text-align: center;
  justify-content: center;
  &:nth-child(1) {
    border-top-right-radius: fn.rem(8);
    border-top-left-radius: fn.rem(8);
  }

  &:nth-last-child(1) {
    border-bottom-right-radius: fn.rem(8);
    border-bottom-left-radius: fn.rem(8);
  }

  &:hover {
    background-color: colors.$color-secondary;
  }
}
