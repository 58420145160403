.back {
  all: unset;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    color: var(--black-color);
  }

  p {
    font-weight: 400;
    font-size: 16px;
    color: #525252;
  }
}
