@use '../../../scss/utils/functions' as fn;
@use '../../../scss/global/colors' as colors;
@import '../../../scss/utils/breakpoints';

.subjectCard {
  width: fn.rem(165);
  height: 246px;
  box-shadow: 0px fn.rem(4) fn.rem(20) rgba(173, 173, 173, 0.16);
  border-radius: 8px;
  padding: fn.rem(12) fn.rem(15) fn.rem(32);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include breakpoint-up('sm') {
    width: fn.rem(214);
  }

  .cardImage {
    width: 100%;
    height: fn.rem(104);
    border-radius: fn.rem(8);
    margin-bottom: fn.rem(26);
    padding: fn.rem(10);

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  p {
    text-transform: capitalize;
    font-weight: 600;
    font-size: fn.rem(24);
    color: colors.$text-dark;
    text-align: left;
    width: 100%;
    margin-bottom: fn.rem(6);
  }

  .cardFooter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    span {
      font-weight: 600;
      font-size: fn.rem(16);
      color: #5d5d5d;
    }
  }
}
