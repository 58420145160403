// @use '../../../scss/global/colors' as colors;
@use '../../../scss/utils/functions' as fn;
@import '../../../scss/utils/breakpoints';
@import '../../../scss/utils/mixins';

.authTemplate {
  @include flex_centered;
  height: 100%;
  // border: 1px solid blue;
  
  // @include breakpoint-up('md') {
    
  // }
  
  @include breakpoint-up('lg') {
    height: 100vh;
    @include flex_align;
    justify-content: space-between;
    width: 100%;
    // border: 1px solid red;
    max-width: 100%;
  }

  @include breakpoint-up('xl') {
    max-width: fn.rem(1500);
    margin: auto;
  }
}

.authImage {
  width: 0;
  height: 100%;
  // border: 1px solid red;

  @include breakpoint-up('lg'){
    width: 50%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

.authForm {
  width: 100%;
  height: 100%;
  min-height: 100vh;

  @include breakpoint-up('lg'){
    width: 50%;
  }
}
