@use '../../../scss/global/colors' as colors;
@use '../../../scss/utils/functions' as fn;
@import '../../../scss/utils/breakpoints';
@import '../../../scss/utils/mixins';

.activities {
  @include flex_column;
  @include flex_centered;

  width: 100%;

  @include breakpoint-up('lg') {
    @include flex_row;
    align-items: flex-start;
    justify-content: space-between;
  }

  h3 {
    color: colors.$header-dark;
    font-weight: 600;
    font-size: fn.rem(25);
  }
}

.activities_main {
  width: 100%;

  @include breakpoint-up('lg') {
    width: 60.6%;
  }

  h3 {
    margin-bottom: fn.rem(20);

    @include breakpoint-up('lg') {
      margin-bottom: fn.rem(40);
    }
  }

  .eventCard {
    width: 100%;
    height: fn.rem(199);
    background: linear-gradient(135deg, #43cbff 0%, #9708cc 100%);
    border-radius: fn.rem(8);
    padding: fn.rem(20) fn.rem(10);
    position: relative;

    @include breakpoint-up('lg') {
      padding: fn.rem(28) fn.rem(59) fn.rem(14);
    }

    .eventCard_content {
      width: 65%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 0;

      p {
        @include flex_centered;
      }

      p,
      a {
        background-color: colors.$light;
        width: fn.rem(93);
        height: fn.rem(20);
        font-size: fn.rem(8);
        font-weight: 600;
        color: colors.$header-dark;
        border-radius: fn.rem(100);
      }

      h2 {
        color: colors.$light;
        text-shadow: 0 0 fn.rem(2) #579bf280;
        font-weight: 700;
        font-size: fn.rem(35);
        margin: 0;

        @include breakpoint-up('lg') {
          font-size: fn.rem(44);
          height: fn.rem(39);
        }
      }

      span {
        all: unset;
        font-weight: 600;
        font-size: fn.rem(30);
        margin: 0;
        color: colors.$light;
        text-shadow: 0 0 2px #579bf280;

        @include breakpoint-up('lg') {
          margin-left: auto;
          height: fn.rem(54);
          font-weight: 600;
          font-size: fn.rem(52);
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint-up('lg') {
          margin-left: auto;
        }
      }
    }

    .eventcard_Img {
      position: absolute;
      right: fn.rem(9);
      bottom: 0;

      width: fn.rem(130);
      height: fn.rem(160);

      @include breakpoint-up('lg') {
        width: fn.rem(203);
        height: fn.rem(228);
        bottom: 0;
      }
    }
  }

  .activitiesTable {
    width: 100%;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      height: 100px;
    }

    h3 {
      margin-bottom: fn.rem(20);
    }

    .tableContent {
      width: fn.rem(550);
      height: 100%;
      background: colors.$light;
      box-shadow: 0px 4px 20px rgba(191, 191, 191, 0.16);
      border-radius: fn.rem(8);

      @include breakpoint-up('md') {
        width: 100%;
        height: fn.rem(434);
      }

      .tableHeader {
        @include flex_align;
        justify-content: flex-start;
        gap: fn.rem(20);
        margin-bottom: fn.rem(10);

        .active {
          color: colors.$color-primary !important;
          border-bottom: 1px solid colors.$color-primary;
          font-weight: 600;
        }

        button {
          all: unset;
          padding: 0 fn.rem(26.5) fn.rem(8);
          font-size: fn.rem(16);
          font-weight: 400;
          color: #7c7c7c;
          cursor: pointer;
        }
      }

      table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0 0.25rem;
        background: #eaeaea;

        tr {
          background-color: #ffffff;
        }

        .green {
          color: #10ad76 !important;
        }

        .pending {
          color: #ce9500;
        }

        .red {
          color: #e0240a;
        }
      }

      button {
        font-size: fn.rem(16);
        margin-top: fn.rem(50);
        margin-right: fn.rem(20);
        float: right;
      }
    }
  }
}

.activities_side {
  width: 100%;
  @include flex_column;
  @include flex_centered;
  flex-direction: column-reverse;
  row-gap: fn.rem(61);

  @include breakpoint-up('lg') {
    width: 34.5%;
    flex-direction: column;
  }

  .leaderBoard {
    width: 100%;

    .leaderBoardActivities {
      width: 100%;
      height: fn.rem(434);
      background: colors.$light;
      box-shadow: 0px 4px 20px rgba(191, 191, 191, 0.16);
      border-radius: fn.rem(8);
      padding: fn.rem(16) fn.rem(8) fn.rem(20);

      button {
        float: right;
        margin-top: fn.rem(42);
        font-size: fn.rem(16);
      }

      .activitiesCard {
        @include flex_align;
        justify-content: space-between;
        width: 100%;
        height: fn.rem(76);
        background: #fafafa;
        box-shadow: 0px 4px 20px rgba(238, 238, 238, 0.16);
        border-radius: fn.rem(4);
        padding: 0 fn.rem(8);
        margin-bottom: fn.rem(8);

        p {
          color: colors.$color-primary;
          font-size: fn.rem(12);
          font-weight: 700;
        }

        .activityUser {
          @include flex_centered;
          gap: fn.rem(12);

          p {
            color: var(--deep-black);
            font-weight: 600;
            font-size: fn.rem(20);
          }
        }

        .activityImg {
          width: fn.rem(44);
          height: fn.rem(44);
          border-radius: 50%;
        }
      }
    }
  }
}
