@use '../../../scss/global/colors' as colors;
@use '../../../scss/utils/functions' as fn;
@import '../../../scss/utils/breakpoints';
@import '../../../scss/utils/mixins';

.sidebar {
  display: none;
  @include breakpoint-up('md') {
    display: block;
    width: 100%;
    height: 100vh;
    padding: 5% 0 10%;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
    
    @include flex_column;
    @include flex_align;
    justify-content: flex-start;
    gap: fn.rem(89);
    border-right: fn.rem(1.5) solid #e6e6f3;

    .sidebar__logo {
      width: fn.rem(82);
      height: fn.rem(72);
    }

    .sidebar__links {
      @include flex_column;
      @include flex_align;
      justify-content: space-between;

      width: 100%;
      height: 100%;
      gap: fn.rem(314);

      & > div {
        width: 100%;
        a, button {
          @include flex_row;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          padding: fn.rem(17) fn.rem(40);
          align-items: center;
          gap: fn.rem(12);

          svg {
            color: #1d1929;
            width: fn.rem(17.3);
            height: fn.rem(17.3);
          }

          p {
            font-size: fn.rem(16);
            color: colors.$text-dark;
          }

          &:hover {
            background-color: colors.$color-primary;

            p,
            svg {
              color: colors.$light;
            }
          }
        }

        button {
          background: none;
        }

        .active {
          background-color: #cfd0e9;

          p {
            font-weight: 700;
            color: colors.$color-primary;
          }

          svg {
            color: colors.$color-primary;
          }
        }

        .sign {
          p {
            color: colors.$text-redII;
          }

          svg {
            color: colors.$text-redII;
          }
        }
      }
    }
  }
}
