@use '../../../scss/global/colors' as colors;
@use '../../../scss/utils/functions' as fn;
@import '../../../scss/utils/breakpoints';
@import '../../../scss/utils/mixins';

.messengerCard {
  width: 100%;
  height: fn.rem(832);
  background-color: colors.$base-bg;
  border-radius: fn.rem(8);
  padding-bottom: fn.rem(32) !important;
}

.cardHead {
  @include flex_align;
  justify-content: space-between;
  vertical-align: top;

  width: 100%;
  padding-right: fn.rem(10);

  small {
    color: #fa2e2e;
    font-weight: 600;
    font-size: fn.rem(16);
  }
}

.messenger_fluid {
  @include flex_centered;
  gap: fn.rem(20);
  padding: fn.rem(11.5) fn.rem(10);

  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(191, 191, 191, 0.16);
  border-top-left-radius: fn.rem(8);

  p {
    color: colors.$header-dark;
    font-size: fn.rem(20);
    font-weight: 600;
  }
}

.messenger_imgHead {
  width: fn.rem(32);
  height: fn.rem(32);
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.message_container {
  @include flex_column;
  gap: fn.rem(50);
  width: 100%;
  padding: fn.rem(110) fn.rem(32) fn.rem(32);
}

.user {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: fn.rem(8);
  width: 50%;
}

.user_img {
  width: fn.rem(32);
  height: fn.rem(32);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.user_message {
  display: flex;
  flex-direction: column;
  gap: fn.rem(8);
  margin-right: auto;

  p {
    color: colors.$header-dark;
    font-size: fn.rem(12);
    font-weight: 600;

    padding: fn.rem(10);

    border-radius: 8px 8px 0px 8px;

    background-color: #eeefff;
  }
}

.messenger {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: fn.rem(8);
  margin-left: auto;
}

.messenger_message {
  display: flex;
  flex-direction: column;
  gap: fn.rem(8);

  p {
    color: colors.$header-dark;
    font-size: fn.rem(12);
    font-weight: 600;

    padding: fn.rem(10);

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    background-color: #eeefff;
  }
}

.messenger_img {
  width: fn.rem(32);
  height: fn.rem(32);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.inputBox {
  width: 100%;
  height: fn.rem(72);
  padding: 0 fn.rem(20);
  position: relative;

  input {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 4px 20px rgba(199, 196, 196, 0.2);
    background: #ffffff;

    padding: fn.rem(26) fn.rem(20);

    &:focus {
      outline: 1px solid colors.$color-primary;
    }

    &::placeholder {
      color: #b0b0b0;
      font-weight: 400;
      font-size: fn.rem(16);
    }
  }

  .inputIcons {
    @include flex_align;
    justify-content: space-between;
    position: absolute;
    right: fn.rem(36);

    top: 50%;
    transform: translateY(-50%);

    width: fn.rem(168.5);

    svg {
      width: fn.rem(24);
      height: fn.rem(24);

      color: #898989;
    }

    button {
      width: fn.rem(101);
      height: fn.rem(40);
      border-radius: fn.rem(8);

      @include flex_align;
      justify-content: space-between;

      color: colors.$light;
      font-size: fn.rem(16);
      font-weight: 400;
      cursor: pointer;

      padding: fn.rem(10) fn.rem(16);

      svg {
        color: colors.$light;
      }
    }
  }
}
