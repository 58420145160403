.dashboard-events {
  width: 100%;
  h3 {
    margin-bottom: 1.25rem;
  }

  .event-card {
    background: var(--white-color);
    width: 100%;
    height: 74px;
    max-height: 74px;
    box-shadow: 0px 0.5rem 1.25rem rgba(174, 173, 173, 0.16);
    border-radius: 0.5rem;

    justify-content: flex-start;
    gap: 0.938rem;
    padding: 0.625rem 0.563rem;
    margin-bottom: 0.25rem;

    .event-date {
      width: 3.813rem;
      height: 3.375rem;
      border-radius: 0.5rem;

      color: var(--white-color);
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.25rem;
    }

    .event-info {
      p {
        color: #2e2e2e;
        font-weight: 500;
        font-size: 1rem;
      }

      span {
        color: #7e7e7e;
        font-size: 0.875rem;
        font-weight: 400;
      }
    }
  }

  button {
    margin-top: 1.188rem;
    font-size: 1rem;
  }
}
