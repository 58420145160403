@use '../../scss/utils/functions' as fn;
@use '../../scss/global/colors' as colors;

.UserSelect {
  width: 100%;
  padding: 4rem 0;
  gap: 0;

  &>h1 {
    margin-bottom: fn.rem(16);
  }

  &>p {
    color: #505050;
    margin-bottom: fn.rem(44);
  }

  .UserSelect__container {
    height: fn.rem(505);
    width: 70%;
    margin-bottom: fn.rem(72);

    .UserSelect__container-inner {
      background-color: colors.$light !important;
      width: 48%;
      height: 100%;
      box-shadow: 0px 4px 20px rgba(172, 172, 172, 0.16);
      border-radius: fn.rem(8);
      transition: 1s all ease-in;
      cursor: pointer;
      
      .img-size {
        width: fn.rem(186);
        height: fn.rem(186);
        margin-bottom: fn.rem(69);
      }

      p {
        text-align: center;
        font-size: fn.rem(28);
        font-weight: 700;
        color: #000000;
      }
    }

    .UserSelect__container-inner:hover {
      border: 1.5px solid colors.$color-primary;
    }

    .active {
      border: 1.5px solid colors.$color-primary;
    }
  }
}

@media screen and (max-width: 1200px) {
  .UserSelect__container {
    width: 80% !important;
    height: fn.rem(420) !important;
  }
}

@media screen and (max-width: 1024px) {
  .UserSelect__container {
    width: 90% !important;
  }
}

@media screen and (max-width: 768px) {
  .UserSelect__container {
    width: 100% !important;
    row-gap: fn.rem(20);
    flex-direction: column;
    height: unset !important;

    .UserSelect__container-inner {
      width: 70% !important;
      height: fn.rem(505) !important;
    }
  }  
}

@media screen and (max-width: 576px) {
  .UserSelect {
    padding: 4rem 0.5rem;
    &>p {
      width: 100%;
      text-align: center;
    }
  }
  .UserSelect__container {

    .UserSelect__container-inner {
      width: 90% !important;
    }
  } 
}
