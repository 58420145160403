@use '../../../scss/global/colors' as colors;
@use '../../../scss/utils/functions' as fn;
@import '../../../scss/utils/breakpoints';
@import '../../../scss/utils/mixins';


.subjects__body_cards {
  @include flex_centered;
  width: 100%;
  flex-wrap: wrap;
  row-gap: fn.rem(24);
  column-gap: fn.rem(10);
  margin-bottom: fn.rem(71);

  @include breakpoint-up('md') {
    @include flex_align;
  justify-content: space-between;
  }
}