@use '../../../scss/global/colors' as colors;
@use '../../../scss/utils/functions' as fn;
// @import '../../../scss/utils/breakpoints';
@import '../../../scss/utils/mixins';

.assignment_table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.25rem;
  color: colors.$text-dark;

  .table_head > tr > th {
    padding-bottom: fn.rem(20);
  }

  .table_head > tr > th:nth-child(1) {
    text-align: left;
    width: 40%;
    padding-left: 1.25rem;
  }

  .table_body {
    tr {
      background: #ffffff;
      box-shadow: 0px 4px 1.25rem rgba(174, 173, 173, 0.16);
    }
    .title {
      @include flex;
      justify-content: flex-start;
      justify-content: flex-start;
      padding-left: 1.25rem;
      text-align: left;
      align-items: center;
      width: 100%;

      .body_image {
        span {
          width: fn.rem(69);
          height: fn.rem(69);
          border-radius: fn.rem(14);
        }
      }

      .body_head {
        @include flex_column;
        align-items: flex-start;
        gap: fn.rem(8);

        .head_title {
          font-weight: 700;
          font-size: 1rem;
        }

        .head_text {
          font-weight: 600;
          font-size: 1rem;
        }
      }
    }

    td {
      text-align: center;
      height: fn.rem(100);
      width: 30%;
      font-size: 1rem;
    }

    .table_grade {
      font-size: 1rem;
      font-weight: 600;
    }

    .completed,
    .pending {
      font-weight: 400;
      font-size: 1rem;
    }

    .completed {
      color: #00915e;
    }

    .pending {
      color: #e4b100;
    }
  }
}
