@use '../../scss/utils/functions' as fn;

.aboutUs {
  padding: 3rem 6.25rem;

  .aboutUs__main {
    position: relative;
    .aboutUs__main-content {
      width: 50%;

      h1 {
        margin-bottom: fn.rem(30);
        span {
          color: #0d1292;
        }
      }
      .aboutUs__main-inner {
        display: flex;
        gap: fn.rem(8);
        margin-bottom: fn.rem(30);

        hr {
          border: 0.2px solid #b1b1d8;
          border-radius: fn.rem(10);
        }
      }
      button {
        width: 10.75rem;
        height: fn.rem(62);
      }
    }
    .aboutUs__img {
      width: 25rem;
      height: 30rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .frame {
      width: fn.rem(250);
      height: fn.rem(400);
      background-color: #b1b1d8;
      border-radius: fn.rem(10);
      position: absolute;
      top: fn.rem(-45);
      right: fn.rem(-100);
      z-index: -1;
    }
    .frame2 {
      width: 100%;
      height: 70px;
      border-radius: 10px;
      background: rgba(231, 231, 251, 0.62);
      position: absolute;
      bottom: -50px;
      z-index: -1;
    }

    .btn-mobile {
      display: none;
    }
  }

  .boxes {
    position: relative;
    height: fn.rem(150);
    margin: 80px auto 0;

    .small {
      position: absolute;
      right: 0;
    }
    .big {
      position: absolute;
      right: fn.rem(45);
      top: fn.rem(36);
    }
    .bigPurple {
      position: absolute;
      bottom: fn.rem(50);
    }
    .smallGreen {
      position: absolute;
      bottom: fn.rem(10);
      left: fn.rem(80);
    }
  }

  .section2 {
    margin-bottom: fn.rem(40);

    .section2__img {
      width: 30rem;
      height: 30rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .section2__content {
      width: 50%;
      h3 {
        font-weight: 800;
        font-size: fn.rem(39);
      }
      p {
        font-weight: 400;
        font-size: fn.rem(20);
        line-height: 165%;
      }
    }
  }

  .section3 {
    margin-bottom: fn.rem(40);
    .section3__img {
      width: 30rem;
      height: 30rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .section3__content {
      width: 50%;
      h3 {
        font-weight: 800;
        font-size: fn.rem(50);
      }
      p {
        font-weight: 400;
        font-size: fn.rem(20);
        line-height: 175%;
      }
    }
  }

  .section4 {
    h1 {
      text-align: center;
      font-weight: 800;
      font-size: fn.rem(50);
    }

    .section4__img-container {
      flex-wrap: wrap;
      padding: fn.rem(70) 0 0;

      .section4__card {
        position: relative;
        width: fn.rem(200);
        height: fn.rem(290);
        background-color: #d9d9d9;
        border-radius: fn.rem(20);

        img {
          position: absolute;
          width: fn.rem(200);
          left: fn.rem(20);
          bottom: fn.rem(-15);
        }
      }
      .section4__card-content {
        text-align: left;
        margin-top: fn.rem(30);
      }
    }
  }
}

.newsletter {
  background: #0d1292;
  padding: fn.rem(40) 0;
  color: #fff;

  h1 {
    font-weight: 700;
    font-size: fn.rem(36);
    margin-bottom: fn.rem(10);
  }

  .newsletter__input {
    width: fit-content;
    background-color: #ffff;
    border-radius: fn.rem(10);
    margin-top: fn.rem(30);

    input {
      width: fn.rem(400);
      height: fn.rem(55);
      border: none;
      outline: none;
      margin-left: fn.rem(10);
    }

    div {
      margin-right: fn.rem(8);
    }
  }
}

// ..........................media query....................../

@media screen and (max-width: 1200px) {
  .aboutUs {
    padding: 3rem 1.5rem;

    .aboutUs__main {
      .frame {
        width: fn.rem(250);
        height: fn.rem(400);
        background-color: #b1b1d8;
        border-radius: fn.rem(10);
        position: absolute;
        top: fn.rem(-45);
        right: fn.rem(-25);
        z-index: -1;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .aboutUs {
    .aboutUs__main {
      .aboutUs__main-content {
        width: 50%;

        h1 {
          margin-bottom: fn.rem(30);
          span {
            color: #0d1292;
          }
        }
        .aboutUs__main-inner {
          display: flex;
          gap: fn.rem(8);
          margin-bottom: fn.rem(30);
        }
        button {
          width: 10.75rem;
          height: fn.rem(62);
        }
      }
      .aboutUs__img {
        width: 25rem;
        height: 30rem;
      }
    }

    .section2 {
      .section2__img {
        width: 25rem;
        height: 25rem;
      }
    }

    .section3 {
      .section3__img {
        width: 25rem;
        height: 25rem;
      }
      .section3__content {
        width: 50%;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .aboutUs {
    padding: 1.5rem 2rem;

    .aboutUs__main {
      position: relative;
      flex-direction: column;

      .aboutUs__main-content {
        width: 100%;
        text-align: center;

        h1 {
          margin-bottom: fn.rem(5);
          font-size: fn.rem(55);
          span {
            color: #0d1292;
          }
        }
        .aboutUs__main-inner {
          display: flex;
          gap: fn.rem(8);
          margin-bottom: fn.rem(30);
          flex-direction: column;

          hr {
            width: 70%;
            margin: auto;
          }
        }
        button {
          display: none;
          width: 10.75rem;
          height: fn.rem(62);
        }
      }
      .aboutUs__img {
        width: 25rem;
        height: 30rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .frame {
        width: fn.rem(250);
        height: fn.rem(400);
        background-color: #b1b1d8;
        border-radius: fn.rem(10);
        position: absolute;
        z-index: -1;
        display: none;
      }
      .frame2 {
        width: 100%;
        height: fn.rem(70);
        border-radius: fn.rem(10);
        background: rgba(231, 231, 251, 0.62);
        position: absolute;
        bottom: fn.rem(-50);
        z-index: -1;
        display: none;
      }

      .btn-mobile {
        margin: fn.rem(40) 0;
        width: 10.75rem;
        height: fn.rem(62);
      }
    }

    .boxes {
      position: relative;
      height: fn.rem(150);
      margin: fn.rem(80) auto 0;
      display: none;

      .small {
        position: absolute;
        right: 0;
      }
      .big {
        position: absolute;
        right: fn.rem(45);
        top: fn.rem(36);
      }
      .bigPurple {
        position: absolute;
        top: fn.rem(-150);
      }
      .smallGreen {
        position: absolute;
        top: fn.rem(-200);
        left: fn.rem(80);
      }
    }

    .section2 {
      flex-direction: column-reverse;
      margin-bottom: fn.rem(40);

      .section2__img {
        width: 30rem;
        height: 30rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .section2__content {
        width: 100%;
        text-align: center;
      }
    }

    .section3 {
      flex-direction: column;
      margin-bottom: fn.rem(40);

      .section3__img {
        width: 30rem;
        height: 30rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .section3__content {
        width: 100%;
        text-align: center;
      }
    }

    .section4 {
      .section4__img-container {
        .section4__card {
          width: fn.rem(150);
          height: fn.rem(220);

          img {
            width: fn.rem(150);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  p {
    font-size: fn.rem(14);
  }
  .aboutUs {
    padding: 1rem 1.25rem;

    .aboutUs__main {
      position: relative;
      .aboutUs__main-content {
        width: 100%;

        h1 {
          margin-bottom: 0;
          font-size: fn.rem(35);
          line-height: fn.rem(50);
          span {
            color: #0d1292;
          }
        }
        .aboutUs__main-inner {
          display: flex;
          gap: fn.rem(8);
          margin-bottom: fn.rem(30);

          hr {
            border: 0.2px solid #b1b1d8;
            border-radius: fn.rem(10);
          }
        }
        button {
          width: 10.75rem;
          height: fn.rem(62);
        }
      }
      .aboutUs__img {
        width: 100%;
        height: 30rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .btn-mobile {
        display: block;
      }
    }

    .section2 {
      margin-bottom: 40px;

      .section2__img {
        width: 100%;
        height: 20rem;
      }
      .section2__content {
        width: 100%;
        text-align: center;
      }
    }

    .section3 {
      margin-bottom: fn.rem(40);

      .section3__img {
        width: 100%;
        height: 20rem;
      }
      .section3__content {
        width: 100%;
      }
    }

    .section4 {
      h1 {
        text-align: center;
        font-weight: 800;
        font-size: fn.rem(40);
      }

      .section4__img-container {
        flex-wrap: wrap;
        justify-content: center;
        gap: fn.rem(50);
      }
    }
  }

  .newsletter {
    background: #0d1292;
    padding: fn.rem(40) 1.25rem;
    color: #fff;

    h1 {
      font-weight: 700;
      font-size: fn.rem(36);
      margin-bottom: fn.rem(10);
    }
    p {
      text-align: center;
    }

    .newsletter__input {
      width: fit-content;
      background-color: #ffff;
      border-radius: fn.rem(10);
      margin-top: fn.rem(30);

      input {
        width: 100%;

        &::placeholder {
          font-size: fn.rem(12);
        }
      }
    }
  }
}
