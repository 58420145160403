// VARIABLES
$base-bg: #ffffff;
$grey-bg: #EAEAEA;
$light-bg: #fdfdfd;
$light: #ffffff;
$dark-bg: #f9f9f9;
$foot-bg: #FAFAFA;

$color-primary: #0d1292;
$color-secondary: #6767A1;
$color-tertiary: #CFD0E9;


$text-dark: #131313;
$text-grey: #434343;
$light-dark:  #222222;
$header-dark: #000000;
$subtext-dark: #0f0d15;
$form-dark: #616161;

$text-green: #10AD76;

$yellow-bg: #FCAB5E;
$text-yellow: #CE9500;
$yellow-light: #E9B575;

$text-red: #CC0642;
$text-redII: #e0240a;

$text-purple: #8A70D6;

$blue-bg: #5792F0;


// $grey-bg: #f5f6fa;
// $light: #ffffff;

// --primary-color: #0d1292;
// --secondary-color: #6767A1;
// --black-color: #131313;
// --secondary-black-color: #434343;
// --deep-black: #000000;
// --white-color: #ffffff;