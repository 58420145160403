@use '../../../scss/global/colors' as colors;
@use '../../../scss/utils/functions' as fn;
@import '../../../scss/utils/breakpoints';
@import '../../../scss/utils/mixins';

.nav {
  @include flex_align;
  justify-content: space-between;
  height: 6.25rem;
  width: 100%;
  padding: 0.875rem 6.25rem;
  background: colors.$light-bg;
  box-shadow: 0px 4px 1.25rem rgba(195, 195, 195, 0.24);
}

.nav-mobile {
  display: none;
}

.nav__logo {
  width: 5.125rem;
  height: 4.5rem;
}

.nav__links {
  @include flex_align;
  justify-content: space-between;
  gap: 2.5rem;

  
  li {
    .active {
      font-weight: 700;
    }
    a {
      color: #626262;
      font-size: fn.rem(18);
      font-weight: 400;
      padding-bottom: 9px;
      position: relative;
      transition: 0.3s all ease-in;

      &:hover::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 50%;
        border-bottom: 1px solid var(--primary-color);
      }
      &:hover {
        color: var(--primary-color);
        font-weight: 700;
      }
    }
  }
}

.nav__search {
  width: 18.4rem;
}

@media screen and (max-width: 1220px) {
  .nav {
    padding: 0.875rem 1rem;
  }
}

@media screen and (max-width: 1040px) {

  .nav__logo {
    width: 3rem;
    height: 2.5rem;
  }

  .nav__links {
    gap: 1rem;
    height: 2rem;

    li {
      a {
        font-size: 1.2rem;
      }
    }
  }

  .nav__search {
    width: 15rem;
    height: 2rem;
  }

  .nav__btn {
    button {
      width: 9rem;
      height: 2rem;
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .nav {
    display: none !important;
  }
  .nav-mobile {
    display: block;
  }
}
