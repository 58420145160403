@use '../utils/functions' as fn;
@import '../../scss/utils/breakpoints';

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: fn.rem(0.5);
  line-height: 1.5;
}
h1 {
  font-weight: 800;
  margin-block-end: 1rem;
}

h2,
h3 {
  font-weight: 700;
  margin-block-end: fn.rem(12);
}

h4,
h5,
h6 {
  font-weight: 600;
  margin-block-end: 0.5rem;
}

h1 {
  // font-size: min(5vw - fn.rem(16), fn.rem(40));
  font-size: clamp(fn.rem(28), fn.rem(16) + 2vw, fn.rem(56));
}

h2 {
  // font-size: min(4vw - fn.rem(16), fn.rem(32));
  font-size: clamp(fn.rem(22), fn.rem(14) + 1.5vw, fn.rem(48));
}

h3 {
  // font-size: min(3vw - fn.rem(16), fn.rem(24));
  font-size: clamp(fn.rem(22), fn.rem(14) + 1.5vw, fn.rem(40));
}

p {
  margin-block: 0;
  font-size: fn.rem(14);
  letter-spacing: fn.rem(0.2);
  line-height: fn.rem(24);
  font-weight: 400;

  @include breakpoint-up('lg') {
    font-size: fn.rem(16);
  }
}
