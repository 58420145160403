@use '../../../scss/global/colors' as colors;
@use '../../../scss/utils/functions' as fn;
@import '../../../scss/utils/breakpoints';
@import '../../../scss/utils/mixins';

.eventCard {
  width: 100%;
  min-height: 100%;
  background: #b0b1d8;
  box-shadow: 0px 4px 20px rgba(172, 172, 172, 0.16);
  margin-bottom: fn.rem(20);
  transition: 0.3s;

  display: flex;
  .cardDate {
    display: none;
    @include breakpoint-up('md') {
      width: 12.3%;
      height: 100%;
      margin: auto 0;
      

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      background: #b0b1d8;

      span {
        font-weight: 600;
        font-size: 1rem;
        color: #651387;
      }
    }
  }

  .cardBody {
    width: 100%;
    all: unset;
    cursor: pointer;
    height: 100%;
    background: colors.$light;

    padding: fn.rem(24) 3% fn.rem(44) 3.3% !important;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    
    @include breakpoint-up('md') {
      width: 87.7%;
    }

    & > div {
      max-width: 90%;
      transition: 3s all ease;
      h5 {
        font-weight: 700;
        font-size: fn.rem(20);
        color: #000000;
        margin-bottom: fn.rem(12);
        
        @include breakpoint-up('md') {
          font-size: fn.rem(24);
        }
      }

      p {
        color: #3f3f3f;
      }

      h6 {
        background: none;
        text-align: left;
        padding: 0;
        margin-top: fn.rem(10);
        color: #3f3f3f;
        font-weight: 700;
        font-size: 1rem;
      }
    }

    svg {
      width: fn.rem(24);
      height: fn.rem(24);
      color: #6b6b6b;
      cursor: pointer;
      transition: 0.5s all ease-in-out;
    }
  }
}
