@use './scss/style';
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap');

:root {
  --primary-color: #0d1292;
  --secondary-color: #6767A1;
  --black-color: #131313;
  --secondary-black-color: #434343;
  --deep-black: #000000;
  --white-color: #ffffff;
}

.App {
  width: 100%;
  max-width: 2000px;
  min-height: 100vh;
}

.img-size {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.app__flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.app__flex-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app__flex-3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.app__flex-4 {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.app__flex-5 {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.column-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.head-text {
  color: var(--black-color);
  font-weight: 800;
  font-size: 3.875rem;
  line-height: 4.9rem;
}

.head-text-2 {
  color: var(--black-color);
  font-weight: 800;
  font-size: 3.125rem;
  line-height: 64px;
}

.sub-text {
  font-weight: 700;
  font-size: 2.25rem;
  color: var(--deep-black);
}

.dashboard-subtext {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--black-color);
}

.p-text {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--secondary-black-color);
}

.p-text-2 {
  color: #222222;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.btn-primary {
  background-color: var(--primary-color);
  outline: none;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(173, 173, 173, 0.16);

  color: var(--white-color);
  font-size: 1.25rem;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background: #353aa4;
  }
}

.btn-secondary {
  border: 1.5px solid var(--primary-color);
  border-radius: 8px;
  outline: none;
  background: none;

  color: var(--primary-color);
  font-size: 1.25rem;
  font-weight: 600;
  cursor: pointer;
}

.btn-tertiary {
  border: none;
  border-radius: 8px;
  outline: none;
  background: #fdfdfd;
  font-size: 20px;
  font-weight: 600;
  color: #121b98;
  cursor: pointer;
}


a {
  text-decoration: none;
  font-weight: 400;
  font-size: 1.25rem;
  color: var(--black-color);
  text-transform: capitalize;
  cursor: pointer;
}

li {
  list-style: none;
}

.test {
  border: 1px solid red;
}

.test1 {
  border: 1px solid blue;
}

.activeNav {
  border: 1px solid red !important;
}

