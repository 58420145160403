.Loading {
    height: 100vh;
    width: 100%;
    background-color: #e9f3f5;
    color: #f7941f;
    display: flex;
    align-items: center;
    justify-content: center;
  
    .Loading__img {
      width: 50px;
      height: 50px;
    }
  
    .loading__text {
      font-size: 2rem;
      font-weight: 600;
    }
  }