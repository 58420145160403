@use '../../../scss/global/colors' as colors;
@use '../../../scss/utils/functions' as fn;
@import '../../../scss/utils/breakpoints';
@import '../../../scss/utils/mixins';

.studentDashboard {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 6%;

  @include breakpoint-up('md') {
    justify-content: flex-start;
  }

  @include breakpoint-up('lg') {
    flex-direction: row;
  }
}

.studentDashboard__body {
  width: 100%;
  margin-bottom: fn.rem(20);

  @include breakpoint-up('md') {
    margin-bottom: 0;
  }

  @include breakpoint-up('lg') {
    width: 64%;
    margin-bottom: 0;
  }

  h2 {
    font-weight: 800;
    font-size: fn.rem(25);
    margin-bottom: fn.rem(20);
  }

  .main_progress {
    color: colors.$text-dark;
    margin-bottom: fn.rem(20);

    @include breakpoint-up('md') {
      margin-bottom: 0;
    }
  }
}

.studentDashboard__side {
  width: 100%;
  @include flex_column;
  flex-direction: column-reverse;

  @include breakpoint-up('md') {
    flex-direction: row;
    gap: 5%;

    margin-top: fn.rem(20);
    padding-top: fn.rem(20);
  }

  @include breakpoint-up('lg') {
    width: 30%;
    flex-direction: column;
    gap: 0;

    margin-top: 0;
    padding-top: 0;
  }
}
