@use '../../scss/utils/functions' as fn;
@import '../../scss/utils/breakpoints';

p {
  font-size: fn.rem(14);

  @include breakpoint-up('md') {
    font-size: fn.rem(16);
  }
}

.faq {
  padding: 3rem 6.25rem;

  h1 {
    text-align: center;
    font-weight: 800;
  }

  .faq__content {
    padding: fn.rem(20) 0;
    margin-bottom: fn.rem(40);

    .faq__content-main {
      width: 50%;

      .content {
        margin: fn.rem(40) 0;

        h3 {
          font-weight: 800;
          font-size: fn.rem(22);
        }
      }
      .icon {
        gap: fn.rem(15);
      }
    }
    .faq__img-container {
      width: 50%;
      padding: fn.rem(50);
      position: relative;

      .faq__img {
        width: 30rem;
        height: 30rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .circle {
          width: fn.rem(180);
          height: fn.rem(180);
          border-radius: 100%;
          background: #fce300;
          position: absolute;
          top: fn.rem(-70);
          right: fn.rem(100);
          z-index: -1;
        }
        .circle2 {
          width: fn.rem(150);
          height: fn.rem(150);
          border-radius: 100%;
          background: #b1b1d8;
          position: absolute;
          top: fn.rem(-30);
          right: fn.rem(200);
          z-index: -1;
        }
        .circle3 {
          width: fn.rem(180);
          height: fn.rem(180);
          border-radius: 100%;
          background: #b1b1d8;
          position: absolute;
          bottom: fn.rem(10);
          left: fn.rem(50);
          z-index: -1;
        }
        .curve {
          position: absolute;
          right: fn.rem(80);
          bottom: fn.rem(15);

          img {
            width: fn.rem(150);
          }
        }
      }
    }
  }
}

// ...................................media query............................

@media screen and (max-width: 992px) {
  .faq {
    padding: inherit 1rem;

   .faq__content {
    flex-direction: column;

    .faq__content-main {
      width: 100%;
    }

    .faq__img-container { 
      margin-top: fn.rem(100);
    }
   }    
  }
}

@media screen and (max-width: 768px) {
  .faq {
    padding: 1.5rem 2.5rem;

    h1 {
      font-size: fn.rem(50);
    }

    .faq__content {
      flex-direction: column;

      .faq__content-main {
        width: 100%;

        .content {
          h3 {
            font-weight: 800;
            font-size: fn.rem(22);
          }
          p {
            font-size: fn.rem(20);
          }
        }
      }
      .faq__img-container {
        padding: fn.rem(50);
        position: relative;

        .faq__img {
          width: 25rem;
          height: 25rem;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          .circle {
            width: fn.rem(100);
            height: fn.rem(100);
            top: fn.rem(-10);
            right: fn.rem(70);
            z-index: -1;
          }
          .circle2 {
            width: fn.rem(80);
            height: fn.rem(80);
            top: fn.rem(15);
            right: fn.rem(140);
            z-index: -1;
          }
          .circle3 {
            width: fn.rem(100);
            height: fn.rem(100);
            bottom: fn.rem(-5);
            left: fn.rem(90);
            z-index: -1;
          }
          .curve {
            right: fn.rem(60);
            bottom: fn.rem(-15);

            img {
              width: fn.rem(150);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .faq {
    padding: 2rem;

    h1 {
      text-align: center;
      font-weight: 800;
      font-size: fn.rem(26);
      margin-bottom: 0;
    }

    .faq__content {
      padding: fn.rem(20) 0;
      margin-bottom: fn.rem(40);

      .faq__content-main {
        padding-top: 0;
        align-items: flex-start;
        justify-content: flex-start;

        .content {
          margin: fn.rem(40) 0;

          &:nth-child(1) {
            margin-top: 0;
          }

          h3 {
            font-weight: 800;
            font-size: fn.rem(18);
          }
        }
        .icon {
          gap: fn.rem(15);
        }
      }
      .faq__img-container {
        display: none;
      }
    }
  }
}
