@use '../../../scss/global/colors' as colors;
@use '../../../scss/utils/functions' as fn;
@import '../../../scss/utils/breakpoints';
@import '../../../scss/utils/mixins';

.library__body_cards {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  grid-column-gap: fn.rem(20);
  grid-row-gap: fn.rem(40);
  overflow-x: scroll;
  padding-bottom: fn.rem(10);
  &::-webkit-scrollbar {
    display: none;
  }
}

.show_scrollbar {
  &::-webkit-scrollbar {
    display: none;
    @include breakpoint-up('md') {
      display: block;
      height: fn.rem(8);
      background: grey;
      border-radius: fn.rem(10);
    }
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background: var(--primary-color);
    border-radius: fn.rem(10);
    cursor: pointer;
  }
}

.show_scrollbar::-webkit-scrollbar-thumb:horizontal {
  background: var(--primary-color);
  border-radius: fn.rem(10);
  cursor: pointer;
}

.show_scrollbar::horizontal {
  background: var(--primary-color);
  border-radius: fn.rem(10);
  cursor: pointer;
}

.library_card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: fn.rem(246);
  width: fn.rem(246);
  height: fn.rem(377);
  font-size: fn.rem(20);

  .subject_img {
    width: 100%;
    height: fn.rem(271);
    margin-bottom: fn.rem(20);

    img {
      width: 100%;
      height: 100%;
    }
  }

  h6 {
    all: unset;
    color: var(--black-color);
    font-weight: 700;
    margin-bottom: fn.rem(4);
  }

  p,
  span {
    font-weight: 600;
    color: #5d5d5d;
    margin-bottom: fn.rem(4);
  }
}

.library__body_btn {
  width: 100%;
  margin-top: fn.rem(40);
}

//-------------------------------------------------SLUGS--------------------------------------//
.slugs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  @include breakpoint-up('md') {
    flex-direction: row;
  }

  .subjectHolder {
    @include flex_column;
    @include flex_centered;
    width: 100%;

    @include breakpoint-up('md') {
      width: 35%;
    }

    @include breakpoint-up('lg') {
      width: 25%;
    }

    .subjectCard {
      width: 100%;
      height: fn.rem(733);
      background: #0d1292;
      border-radius: fn.rem(8);
      margin-bottom: fn.rem(34);
      padding: fn.rem(12) fn.rem(14);
      color: colors.$light;

      @include breakpoint-up('sm') {
        width: 75%;
      }

      @include breakpoint-up('md') {
        width: 100%;
      }

      .cardImg {
        width: 100%;
        height: fn.rem(271);
        margin-bottom: fn.rem(15);

        img {
          object-fit: contain;
        }
      }

      .cardContent {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: fn.rem(12);
        h6 {
          all: unset;
          font-weight: 400;
          font-size: fn.rem(20);
          color: #e7e6e6;
        }

        p {
          font-weight: 700;
          font-size: fn.rem(20);
        }
      }

      .teacherContainer {
        @include flex_column;
        @include flex_centered;
        margin-top: fn.rem(22);
        width: 100%;

        .teacher {
          width: fn.rem(152);
          height: fn.rem(152);
        }

        h6 {
          all: unset;
          font-weight: 400;
          font-size: fn.rem(20);
          color: #e7e6e6;
          margin-top: fn.rem(12);
        }

        p {
          font-weight: 700;
          font-size: fn.rem(20);
          margin-top: fn.rem(8);
        }
      }
    }

    button {
      width: 100%;
      height: fn.rem(48);
    }
  }

  .accordionContainer {
    width: 100%;
    padding-top: fn.rem(30);

    @include breakpoint-up('md') {
      width: 62%;
    }

    @include breakpoint-up('lg') {
      width: 71%;
    }

    .accordionHead {
      @include flex_column;
      @include flex_centered;
      margin-bottom: fn.rem(34);

      @include breakpoint-up('lg') {
        @include flex_align;
        flex-direction: row;
        justify-content: space-between;
      }

      & > div {
        @include flex_centered;
      }
    }

    .accordionHolder {
      width: 100%;
      @include flex_column;
      @include flex_centered;
      gap: fn.rem(8);

      .accordion {
        @include flex_column;
        align-items: flex-start;

        width: 100%;
        height: 100%;
        padding: fn.rem(20);

        background-color: #f7f7f7;
        border-radius: fn.rem(8);
        cursor: pointer;

        & > div {
          @include flex_align;
          justify-content: space-between;

          width: 100%;
        }

        h6 {
          all: unset;
          font-weight: 700;
          font-size: fn.rem(20);
          color: colors.$text-dark;
        }

        p {
          margin-top: fn.rem(24);
        }
      }
    }
  }
}
