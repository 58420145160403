.testimonials {
  padding: 120px 0;

  h1 {
    color: #000000;
    font-size: 2.25rem;
    margin-bottom: 0.75rem;
  }

  & > p {
    font-size: 0.875rem;
    margin-bottom: 13px;
  }
}

.mySwiper {
  width: 100%;
  padding: 30px 0;

  .swipeItem {
    width: 29rem;
    height: 19.6rem;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(173, 173, 173, 0.16);
    border-radius: 0.5rem;
    padding: 1.25rem 2.3rem 2.8rem 2.06rem;

    .card-head {
      gap: 0.8rem;
      margin-bottom: 1rem;

      .testifier {
        width: 3.75rem;
        height: 3.75rem;
      }

      .testitier-details {
        h6 {
          color: var(--black-color);
          font-size: 1.25rem;
          font-weight: 700;
        }

        p {
          color: #686868;
          font-weight: 400;
          font-size: 1rem;
        }
      }
    }

    .card-body {
      font-size: 1rem;
      font-weight: 400;
      color: var(--secondary-black-color);
      margin-bottom: 0.8rem;
    }

    .card-star {
      width: 8.5rem;
      height: 1.5rem;
    }
  }
}

@media screen and (max-width: 480px) {
  .testimonials {
    padding: 2rem 0.5rem;

    &>h1 {
      text-align: center;
    }

    &>p{ 
      text-align: center;
      line-height: 1.2rem;
    }
  }

  .mySwiper {
    width: 100%;
    padding: 30px 0;
    justify-content: center !important;
    align-items: center !important;
  
    .swipeItem {
      width: 350px;
      padding: 1rem;
      box-shadow: 0px 2px 5px rgba(173, 173, 173, 0.16);
    }
  }
}
