@use '../../../scss/global/colors' as colors;
@use '../../../scss/utils/functions' as fn;
@import '../../../scss/utils/breakpoints';
@import '../../../scss/utils/mixins';

.notifications {
  width: 100%;

  @include breakpoint-up('lg') {
    width: 90%;
  }
}

.notification_head {
  @include flex_align;
  justify-content: space-between;
  margin-bottom: fn.rem(24);

  width: 100%;

  button {
    all: unset;
    color: colors.$color-primary;
    font-family: 'inter';
    font-size: fn.rem(16);
    font-weight: 400;
    cursor: pointer;

    @include flex_centered;
    gap: fn.rem(9);
  }
}

.notification_cards {
  @include flex_column;
  @include flex_centered;
  gap: fn.rem(24);
}

.card {
  @include flex_column;
  width: 100%;
  background-color: colors.$light;
  padding: fn.rem(12) fn.rem(10);
  gap: fn.rem(12);
  position: relative;

  @include breakpoint-up('md') {
    @include flex_row;
    width: 100%;
    background-color: colors.$light;
    padding: fn.rem(12) fn.rem(10);
    gap: fn.rem(12);
  }
}

.card_img {
  width: fn.rem(56);
  height: fn.rem(56);
  border-radius: 50%;
}

.card_img > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.card_text {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card_text > h1 {
  color: colors.$text-dark;
  font-weight: 600;
  font-size: fn.rem(16);

  @include breakpoint-up('md') {
    font-size: fn.rem(20);
  }
}

.card_text > p {
  color: colors.$form-dark;
  width: 100%;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: fn.rem(14);
  margin-bottom: fn.rem(12);

  @include breakpoint-up('md') {
    font-size: fn.rem(16);
    line-height: fn.rem(28);
  }
}

.card_text > small {
  color: colors.$form-dark;
  font-weight: 400;
  font-size: fn.rem(14);

  @include breakpoint-up('md') {
    font-size: fn.rem(20);
  }
}

.btns {
  width: fn.rem(68);

  position: absolute;
  top: fn.rem(13);
  right: fn.rem(20);

  @include flex_centered;
  gap: fn.rem(20);

  svg {
    width: fn.rem(24);
    height: fn.rem(24);
    color: #cc3606;
    cursor: pointer;
  }
}
